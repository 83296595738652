import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

export default () => {
  return (
    <Layout>
        <SEO 
            title="ارور 404"
            description="صفحه مورد نظر شما وجود ندارد"
        />
      <div className="wrapper text-center">
        <h1 className="h1">صفحه مورد نظر وجود ندارد</h1>
        <Link to="/" className="btn btn-primery">
          بازگشت به صفحه اصلی
        </Link>
      </div>
    </Layout>
  )
}
